/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.pre_service-checklist {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
}
.pre_service-checklist .pre_service-checklist-block {
  flex-grow: 1;
  flex-basis: 325px;
  min-width: 325px;
  max-width: 400px;
  margin-bottom: 24px;
  padding-right: 16px;
}
.mobile .pre_service-checklist .pre_service-checklist-block {
  padding-right: 0;
  max-width: 100%;
  flex-basis: 275px;
  min-width: 275px;
}
.tgd1x-camera-model {
  float: left;
}
.tgd1x-camera-model-input {
  width: 135px;
  margin-left: 4px;
  font-size: 13px;
}
