/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.pre_service-images {
  display: flex;
  flex-wrap: wrap;
}
.pre_service-images .pre_service-image-comment {
  flex-grow: 1;
  flex-basis: 475px;
  margin-bottom: 24px;
}
.pre_service-images .pre_service-image-comment .topic {
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
  text-align: center;
  margin-bottom: 8px;
  color: #1890ff;
  font-size: 1.1em;
}
