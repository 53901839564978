/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.pre_service-checklist-column {
  display: flex;
  flex-direction: row;
}
.pre_service-checklist-block {
  flex-grow: 1;
  flex-basis: 400px;
  min-width: 325px;
  max-width: 400px;
  margin-bottom: 24px;
  padding-right: 16px;
}
.mobile .pre_service-checklist-block {
  max-width: 100%;
  padding-right: 0;
  flex-basis: 275px;
  min-width: 275px;
}
@media print {
  .checklist-row > label {
    -webkit-print-color-adjust: exact;
  }
}
