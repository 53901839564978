/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.checklist-item {
  display: flex;
  justify-content: left;
  border-bottom: 1px dashed #d9d9d9;
}
.checklist-item:last-child {
  border-bottom: none;
}
.checklist-item .checklist-label {
  display: flex;
  flex-basis: 80%;
  justify-content: left;
}
.checklist-item .checklist-no-value {
  display: flex;
  justify-content: center;
}
.checklist-item .checklist-choice {
  display: flex;
  flex-basis: 10%;
  justify-content: center;
}
