/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.service-files-panel {
  max-width: 900px;
}
.file-section-title {
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
  text-decoration: underline;
  color: #D22027;
  margin-bottom: 12px;
}
.file-section-empty {
  color: #f5222d;
  padding-left: 24px;
  margin-bottom: 16px;
}
.service-file {
  position: relative;
  padding-right: 24px;
  margin-bottom: 4px;
}
.service-file .service-file-delete {
  position: absolute;
  top: 0;
  right: 0;
  color: #f5222d;
}
