/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.image-comment-container {
  width: 600px;
  width: fit-content;
  min-width: 275px;
  margin: 0 auto;
}
.image-comment-canvas {
  background: #fff;
  display: inline-block;
  margin: 0 auto;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}
.image-comment-command {
  text-align: right;
  padding-top: 8px;
  padding-bottom: 8px;
}
.image-comment-item {
  padding: 4px 8px;
  display: flex;
  flex-wrap: nowrap;
  text-align: left;
}
.image-comment-item .item-no {
  margin-right: 4px;
  line-height: 32px;
  user-select: none;
}
.image-comment-item .item-input {
  flex-grow: 1;
  margin-right: 8px;
}
.image-comment-item .item-input-read-only {
  flex-grow: 1;
  margin-top: 3px;
  border-width: 1px;
  text-indent: 5px;
  border-radius: 5px;
  border-color: gray;
  border-style: ridge;
  margin-right: 8px;
}
.image-comment-item .item-btn {
  margin-top: 4px;
}
.image-comment-item.selected {
  background-color: #d9f7be;
}
.image-comment-item.readonly {
  cursor: pointer;
  padding: 8px;
  user-select: none;
}
.image-comment-item.readonly .item-no,
.image-comment-item.readonly .item-input {
  line-height: 1.4;
}
.image-comment-container-small .image-comment-item .item-no {
  line-height: 22px;
  font-size: 0.95em;
}
.image-comment-container-small .image-comment-item .item-btn {
  margin-top: 2px;
}
.image-comment-container-small .image-comment-item .item-btn.ant-btn-icon-only.ant-btn-sm {
  width: 20px;
  height: 20px;
  min-width: 20px;
  font-size: 12px;
}
.image-comment-container-small .image-comment-item input {
  font-size: 0.95em;
}
