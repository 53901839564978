/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.invalid-box {
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
  text-align: center;
  padding: 24px 36px;
  max-width: 600px;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  margin: 24px auto;
}
.invalid-box .title {
  font-size: 3em;
}
.invalid-box .description {
  margin-top: 24px;
  font-size: 1em;
}
